@import"https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";*
{
    padding:0;
    margin:0;
    box-sizing:border-box
}
html{
    overflow-x:hidden
}
a{
    text-decoration:none
}
body{
    overflow-x:hidden;
    background:#fff;
    font-family:"Grandstander",sans-serif
}
.container{
    max-width:1276px;
    padding:0 15px;
    margin:0 auto;
    width:100%
}
header{
    margin-top:45px
}
header .container{
    display:flex;
    align-items:center;
    justify-content:space-between;
    grid-gap:20px
}
header .mobile-btn{
    display:none
}
@media screen and (max-width: 500px){
    header {
        display: none;
    }
    header .mobile-btn{
        display:flex;
        position:relative;
        z-index:10
    }
}
header .mobile-btn .default{
    display:block
}
header .mobile-btn .close{
    display:none
}
header .mobile-btn.active .default{
    display:none
}
header .mobile-btn.active .close{
    display:block
}
header .header__logo{
    position:relative;
    z-index:10;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    transition:.3s all
}
header .header__logo:hover{
    opacity:.7;
    transition:.3s all
}
header .header__logo img{
    width:246px
}
header .header__links{
    display:flex;
    align-items:center;
    justify-content:flex-end;
    grid-gap:64px
}
header .header__links .social__links{
    display:none
}
@media screen and (max-width: 500px){
    header .header__links .social__links{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:center;
        grid-gap:16px
    }
    header .header__links .social__links a img{
        width:54px
    }
}
@media screen and (max-width: 500px){
    header .header__links{
        transform:translateY(-100%);
        transition:.3s all;
        border-radius:40px;
        border-bottom:4px solid var(--f-8-f-7-ff, #f8f6ff);
        background:rgba(0,0,0,.8);
        box-shadow:0px 4px 67.7px 0px #000;
        backdrop-filter:blur(7.75px);
        position:absolute;
        width:100%;
        top:0;
        left:0;
        z-index:5;
        padding:20px 18px;
        padding-top:140px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        grid-gap:32px
    }
    header .header__links.active{
        transition:.3s all;
        transform:translateX(0%)
    }
}
header .header__links .links__link{
    color:#000;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%;
    transition:.3s all
}
@media screen and (max-width: 500px){
    header .header__links .links__link{
        width:100%;
        font-size:36px
    }
}
header .header__links .links__link:hover{
    opacity:.7;
    transition:.3s all
}
header .header__links .links__btn{
    padding:14px 20px;
    color:#000;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%;
    border-radius:24px;
    border:2px solid var(--f-8-f-7-ff, #000)
}
header .header__links .links__btn:hover{
    background:#f8f6ff;
    color:#000
}
@media screen and (max-width: 500px){
    header .header__links .links__btn{
        width:100%;
        text-align:center;
        justify-content:center
    }
}
section.intro{
    margin-bottom:419px;
    margin-top:261px
}
@media screen and (max-width: 500px){
    section.intro{
        margin-bottom:150px
    }
}
section.intro img.picture{
    max-width: 600px;
    width: 100%;
    position: absolute;
    right: -50px;
    pointer-events: none;
    top: -250px;
    z-index: -1;
    border-radius: 14px;
}
@media screen and (max-width: 500px){
    section.intro img.picture{
        right:unset
    }
}
section.intro .container{
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:flex-start
}
section.intro h2{
    color:#000;
    display:flex;
    align-items:center;
    justify-content:center;
    grid-gap:30px;
    font-size:72px;
    font-style:normal;
    font-weight:700;
    line-height:normal;
    margin-bottom:9px
}
@media screen and (max-width: 500px){
    section.intro h2{
        font-size:46px
    }
}
section.intro h2 svg{
    width:106px;
    margin-bottom:10px
}
section.intro h1{
    margin-bottom:32px
}
section.intro h1 img{
    max-width:524px;
    width:100%
}
section.intro h3{
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%;
    margin-bottom:32px
}
@media screen and (max-width: 500px){
    section.intro h3 br{
        display:none
    }
}
section.intro .intro__links{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:16px
}
@media screen and (max-width: 500px){
    section.intro .intro__links{
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%
    }
}
section.intro .intro__links .links__link,    section.link_swip .links__link.white,section.link_swip  .links__link.border{
    padding:14px 20px;
    display:flex;
    align-items:center;
    justify-content:center;
    grid-gap:10px;
    font-size:20px;
    font-style:normal;
    font-weight:500;
    line-height:140%
}
swiper-slide{
    height: auto;
}
section.link_swip .links__link.white,section.link_swip  .links__link.border{
    height: 100%;
}
section.link_swip .links__link.white,section.link_swip  .links__link.border img, section.link_swip .links__link.white,section.link_swip  .links__link.border svg{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}
section.link_swip .links__link.white,section.link_swip  .links__link.border, section.link_swip .links__link.white,section.link_swip  .links__link.border svg{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}
section.link_swip {

margin: 50px 0 100px;
}
@media screen and (max-width: 500px){
    section.intro .intro__links .links__link{
        width:100%
    }

    section.link_swip {

        margin: -88px 0 50px;
        }
}
section.intro .intro__links .links__link svg{
    width:24px
}
section.intro .intro__links .links__link.white, section.link_swip .links__link.white{
    color:var(--292933, #050505);
    border-radius:24px;
    background:var(--f-8-f-7-ff, #f8f6ff);
    transition:.3s all
}
section.intro .intro__links .links__link.white:hover,section.link_swip  .links__link.white:hover{
    opacity:.5;
    transition:.3s all
}
section.intro .intro__links .links__link.border,section.link_swip  .links__link.border{
    border-radius:24px;
    border:2px solid var(--f-8-f-7-ff, #000);
    color:var(--f-8-f-7-ff, #000)
}
section.intro .intro__links .links__link.border:hover,section.link_swip  .links__link.border:hover{
    color:#050505;
    background:#696969
}
section.about{
    margin-bottom:245px
}
@media screen and (max-width: 500px){
    section.about{
        margin-bottom:159px
    }
}
section.about .container{
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    grid-gap:20px
}
@media screen and (max-width: 500px){
    section.about .container{
        flex-wrap:wrap
    }
}
section.about .about__picture{
    max-width:484px;
    width:100%;
    border-radius: 14px;
}
section.about .about__content{
    max-width:613px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    grid-gap:32px
}
section.about .about__content .content__title{
    color:#000;
    font-size:72px;
    font-style:normal;
    font-weight:700;
    line-height:normal
}
section.about .about__content .content__text{
    color:#000;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%
}
section.comment{
    margin-bottom:368px
}
section.comment .container{
    display:flex;
    align-items:center;
    justify-content:center
}
.comment__content svg path {
    fill: #000;
}
section.comment .container .comment__content{
    padding:32px;
    max-width:780px;
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    grid-gap:24px;
    position:relative;
    border-radius:24px;
    border:4px solid var(--f-8-f-7-ff, #000)
}
section.comment .container .comment__content::after{
    content:"";
    max-width:250px;
    width:100%;
    height:250px;
    background:#30e9a1;
    opacity:.2;
    filter:blur(108.0999984741px);
    border-radius:250px;
    position:absolute;
    top:-125px;
    left:-125px
}
section.comment .container .comment__content img.picture{
    max-width:296px;
    width:100%;
    position:absolute;
    right:-310px;
    bottom:-250px
}
@media screen and (max-width: 500px){
    section.comment .container .comment__content img.picture{
        position:absolute;
        right:0;
        bottom:-350px
    }
}
section.comment .container .comment__content svg{
    width:64px
}
section.comment .container .comment__content .content__text{
    width:calc(100% - 64px - 24px);
    color:#000;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%
}
section.base{
    margin-bottom:511px
}
@media screen and (max-width: 500px){
    section.base{
        margin-bottom:140px
    }
}
section.base .container{
    position:relative;
    display:flex;
    align-items:flex-start;
    justify-content:flex-end
}
@media screen and (max-width: 500px){
    section.base .container{
        justify-content:center
    }
}
section.base img.picture{
    max-width:484px;
    width:100%;
    position:absolute;
    left:0;
    border-radius: 14px;
}
@media screen and (max-width: 500px){
    section.base img.picture{
        left:unset;
        width:100%;
        top:180px;
        right:-40px
    }
}
section.base .base__content{
    max-width:613px;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    grid-gap:32px
}
section.base .base__content .content__title{
    color:#000;
    font-family:Grandstander;
    font-size:68px;
    font-style:normal;
    font-weight:700;
    line-height:normal
}
@media screen and (max-width: 500px){
    section.base .base__content .content__title{
        font-size:46px;
        margin-bottom:500px
    }
}
section.base .base__content .content__text{
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%
}
section.base .base__content .content__lists{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    grid-gap:20px
}
section.base .base__content .content__lists .lists__list{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:24px;
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:700;
    line-height:140%
}
section.base .base__content .content__lists .lists__list svg{
    width:24px
}
section.base .base__content .content__links{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:16px
}
@media screen and (max-width: 500px){
    section.base .base__content .content__links{
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center
    }
}
section.base .base__content .content__links .links__link{
    padding:14px 20px;
    display:flex;
    align-items:center;
    justify-content:center;
    grid-gap:10px;
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:500;
    line-height:140%
}
@media screen and (max-width: 500px){
    section.base .base__content .content__links .links__link{
        width:100%
    }
}
section.base .base__content .content__links .links__link svg{
    width:24px
}
svg path {
    fill: #000;
}
section.base .base__content .content__links .links__link.gradient{
    border-radius:24px;
    border:2px solid var(--f-8-f-7-ff, #f8f6ff);
    background:var(--Linear, linear-gradient(99deg, #2ee6a6 1.93%, #3e54c8 47.51%, #7b3ec8 96.04%));
    transition:.3s all
}
section.base .base__content .content__links .links__link.gradient:hover{
    opacity:.7;
    transition:.3s all
}
section.base .base__content .content__links .links__link.border{
    border-radius:24px;
    border:2px solid var(--f-8-f-7-ff, #000)
}
section.base .base__content .content__links .links__link.border:hover{
    background:#f8f6ff;
    color:#000
}
section.base .base__content .content__links .links__link.border:hover svg path{
    fill:#000
}

--------------------------------------------

section.base2{
    margin-bottom:511px
}
@media screen and (max-width: 500px){
    section.base2{
        margin-bottom:140px
    }
}
section.base2 .container{
    position:relative;
    display:flex;
    align-items:flex-start;
    justify-content:center
}
@media screen and (max-width: 500px){
    section.base2 .container{
        justify-content:center
    }
}
section.base2 img.picture{
    max-width:717px;
    width:100%;
    position:absolute;
    left:0
}
@media screen and (max-width: 500px){
    section.base2 img.picture{
        left:unset;
        width:100%;
        top:180px;
        right:-40px
    }
}
section.base2 .base2__content{
    max-width:613px;
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    grid-gap:32px
}
section.base2 .base2__content .content__title{
    color:#000;
    font-family:Grandstander;
    font-size:72px;
    font-style:normal;
    font-weight:700;
    line-height:normal
}
#MAYAN_SWAP_PV_ROOT{
    height: 450px;
}
.base2{
    padding-bottom: 100px;
}
section.base2 .container{
    justify-content: space-between;
}
@media screen and (max-width: 700px){
    .base2{
        padding-bottom: 0;
    }
    section.base2 .container{
        justify-content: center;
    }
}
@media screen and (max-width: 500px){
    section.base2 .base2__content .content__title{
        font-size:46px;
        margin-bottom:0
    }
    section.base2 .base2__content .content__text{
        margin-bottom: 30px;
    }
    #MAYAN_SWAP_PV_ROOT{
        max-width: 90%;
        margin: 0 auto;


    }
}
section.base2 .base2__content .content__text{
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%
}
section.base2 .base2__content .content__lists{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    grid-gap:20px
}
section.base2 .base2__content .content__lists .lists__list{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:24px;
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:700;
    line-height:140%
}
section.base2 .base2__content .content__lists .lists__list svg{
    width:24px
}
section.base2 .base2__content .content__links{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:16px
}
@media screen and (max-width: 500px){
    section.base2 .base2__content .content__links{
        width:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center
    }
}
section.base2 .base2__content .content__links .links__link{
    padding:14px 20px;
    display:flex;
    align-items:center;
    justify-content:center;
    grid-gap:10px;
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:500;
    line-height:140%
}
@media screen and (max-width: 500px){
    section.base2 .base2__content .content__links .links__link{
        width:100%
    }
}
section.base2 .base2__content .content__links .links__link svg{
    width:24px
}
section.base2 .base2__content .content__links .links__link.gradient{
    border-radius:24px;
    border:2px solid var(--f-8-f-7-ff, #000);
    background:var(--Linear, linear-gradient(99deg, #2ee6a6 1.93%, #3e54c8 47.51%, #7b3ec8 96.04%));
    transition:.3s all
}
section.base2 .base2__content .content__links .links__link.gradient:hover{
    opacity:.7;
    transition:.3s all
}
section.base2 .base2__content .content__links .links__link.border{
    border-radius:24px;
    border:2px solid var(--f-8-f-7-ff, #000)
}
section.base2 .base2__content .content__links .links__link.border:hover{
    background:#f8f6ff;
    color:#000
}
section.base2 .base2__content .content__links .links__link.border:hover svg path{
    fill:#000
}

section.features{
    margin-bottom:368px
}
@media screen and (max-width: 700px){
    section.base2 .container{
       flex-wrap: wrap;
    }}
@media screen and (max-width: 500px){
    section.features{
        margin-bottom:200px
    }
}
section.features .container{
    position:relative
}
section.features .container::after{
    content:"";
    position:absolute;
    top:10px;
    left:-200px;
    max-width:400px;
    max-height:400px;
    height:100%;
    width:100%;
    border-radius:702.76px;
    opacity:.2;
    background:#2ebae6;
    filter:blur(108.0999984741px);
    z-index:-1
}
section.features .container::before{
    content:"";
    position:absolute;
    top:10px;
    right:-164px;
    width:883.726px;
    height:512.011px;
    border-radius:883.726px;
    opacity:.2;
    background:linear-gradient(99deg, #7b3ec8 1.93%, #3e54c8 47.51%, #7b3ec8 96.04%);
    filter:blur(108.0999984741px);
    z-index:-1
}
section.features .features__title{
    display:flex;
    align-items:flex-end;
    justify-content:flex-start;
    grid-gap:32px;
    color:#000;
    font-size:72px;
    font-style:normal;
    font-weight:700;
    line-height:normal;
    margin-bottom:63px
}
section.features .features__title svg{
    width:122px
}
@media screen and (max-width: 500px){
    section.features .features__title{
        font-size:46px
    }
}
section.features .features__grid{
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    grid-gap:32px
}
@media screen and (max-width: 500px){
    section.features .features__grid{
        grid-template-columns:repeat(1, 1fr)
    }
}
section.features .features__grid .grid__block{
    width:100%;
    padding:32px;
    border-radius:24px;
    border:4px solid var(--f-8-f-7-ff, #f8f6ff);
    background:var(--Linear, linear-gradient(180deg, rgba(191, 52, 255, 0.1) 0%, rgba(255, 1, 179, 0) 100%));
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:space-between
}
section.features .features__grid .grid__block .block__info{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    grid-gap:16px;
    min-height:258px;
    margin-bottom:35px
}
section.features .features__grid .grid__block .block__info svg{
    width:64px
}
section.features .features__grid .grid__block .block__info .info__title{
    color:#000;
    font-size:36px;
    font-style:normal;
    font-weight:700;
    line-height:normal
}
section.features .features__grid .grid__block .block__info .info__text{
    color:#000;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%
}
section.features .features__grid .grid__block .block__btn{
    padding:14px 20px;
    border-radius:24px;
    border:2px solid var(--f-8-f-7-ff, #f8f6ff);
    display:flex;
    align-items:center;
    justify-content:center;
    color:#000;
    font-size:20px;
    font-style:normal;
    font-weight:500;
    line-height:140%;
    grid-gap:10px
}
section.features .features__grid .grid__block .block__btn:hover{
    color:#000;
    background:#f8f6ff
}
section.features .features__grid .grid__block .block__btn:hover svg path{
    fill:#000
}
section.features .features__grid .grid__block .block__btn svg{
    width:24px
}
section.social{
    margin-bottom:366px
}
@media screen and (max-width: 500px){
    section.social{
        margin-bottom:270px
    }
}
section.social img.picture{
    max-width:547px;
    width:100%
}
section.social .container{
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    position:relative;
    grid-gap:20px
}
@media screen and (max-width: 500px){
    section.social .container{
        flex-wrap:wrap;
        flex-direction:column-reverse
    }
}
section.social .social__content{
    max-width:540px;
    width:100%
}
section.social .social__content .content__title{
    color:#000;
    font-family:Grandstander;
    font-size:72px;
    font-style:normal;
    font-weight:700;
    line-height:normal;
    margin-bottom:32px
}
@media screen and (max-width: 500px){
    section.social .social__content .content__title{
        font-size:46px
    }
}
section.social .social__content .content__list{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:16px
}
section.social .social__content .content__list .list__link{
    transition:.3s all
}
section.social .social__content .content__list .list__link:hover{
    opacity:.7;
    transition:.3s all
}
section.social .social__content .content__list .list__link svg{
    width:83px
}
section.why-choose{
    margin-bottom:180px
}
section.why-choose .container{
    display:flex;
    flex-direction:column;
    align-items:flex-start
}
section.why-choose .why-choose__title{
    display:flex;
    margin-bottom:56px;
    align-items:center;
    justify-content:center;
    position:relative;
    color:#000;
    font-size:72px;
    font-style:normal;
    font-weight:700;
    line-height:normal
}
@media screen and (max-width: 500px){
    section.why-choose .why-choose__title{
        font-size:46px
    }
}
section.why-choose .why-choose__title::after{
    content:"";
    border-radius:876.754px;
    opacity:.26;
    background:var(--Linear, linear-gradient(99deg, #2ee6a6 1.93%, #3e54c8 47.51%, #7b3ec8 96.04%));
    transform:rotate(89.541deg);
    filter:blur(108.0999984741px);
    max-width:268.147px;
    max-height:876.754px;
    width:100%;
    height:100%;
    position:absolute;
    z-index:-1
}
section.why-choose .why-choose__grid{
    display:grid;
    align-items:flex-start;
    grid-template-columns:repeat(4, 1fr);
    grid-gap:20px
}
@media screen and (max-width: 500px){
    section.why-choose .why-choose__grid{
        grid-template-columns:repeat(1, 1fr);
        grid-gap:40px
    }
}
section.why-choose .why-choose__grid .grid__block{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    grid-gap:19px;
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%
}
section.why-choose .why-choose__grid .grid__block:nth-child(2){
    margin-top:131px
}
@media screen and (max-width: 500px){
    section.why-choose .why-choose__grid .grid__block:nth-child(2){
        margin-top:0
    }
}
section.why-choose .why-choose__grid .grid__block:nth-child(3){
    margin-top:320px
}
@media screen and (max-width: 500px){
    section.why-choose .why-choose__grid .grid__block:nth-child(3){
        margin-top:0
    }
}
section.why-choose .why-choose__grid .grid__block:nth-child(4){
    margin-top:83px
}
@media screen and (max-width: 500px){
    section.why-choose .why-choose__grid .grid__block:nth-child(4){
        margin-top:0
    }
}
section.faq{
    margin-bottom:271px
}
section.faq .faq__title{
    margin-bottom:64px;
    display:flex;
    align-items:flex-end;
    justify-content:flex-start;
    grid-gap:32px;
    color:#000;
    font-family:Grandstander;
    font-size:72px;
    font-style:normal;
    font-weight:700;
    line-height:normal
}
section.faq .faq__title svg{
    width:120px
}
section.faq .faq__list{
    width:100%;
    display:flex;
    flex-direction:column;
    grid-gap:32px
}
section.faq .faq__list .list__block{
    padding:32px;
    border-radius:24px;
    border:4px solid var(--f-8-f-7-ff, #f8f6ff);
    background:var(--Linear, linear-gradient(180deg, rgba(191, 52, 255, 0.1) 0%, rgba(255, 1, 179, 0) 100%));
}
section.faq .faq__list .list__block .block__header{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    color:#000;
    font-family:Grandstander;
    font-size:36px;
    font-style:normal;
    font-weight:700;
    line-height:normal
}
@media screen and (max-width: 500px){
    section.faq .faq__list .list__block .block__header{
        font-size:16px
    }
}
section.faq .faq__list .list__block.active .block__header svg{
    transform:rotate(90deg)
}
section.faq .faq__list .list__block .block__content{
    display:none;
    color:#000;
    margin-top:24px;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%
}
@media screen and (max-width: 500px){
    section.faq .faq__list .list__block .block__content{
        font-size:16px
    }
}
footer{
    border-radius:80px 80px 0px 0px;
    border-top:4px solid var(--f-8-f-7-ff, #000);
    /* background:var(--Linear, linear-gradient(180deg, rgba(191, 52, 255, 0.1) 0%, rgba(255, 1, 179, 0) 100%)); */
    display:flex;
    align-items:center;
    justify-content:center
}
/* footer::after{
    content:"";
    position:absolute;
    border-radius:1744.768px;
    opacity:.26;
    background:var(--Linear, linear-gradient(99deg, #2ee6a6 1.93%, #3e54c8 47.51%, #7b3ec8 96.04%));
    filter:blur(108.0999984741px);
    max-width:1744.768px;
    max-height:341.704px;
    height:100%;
    width:100%
} */
footer .container{
    z-index:2;
    padding-top:118px;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:flex-start
}
@media screen and (max-width: 500px){
    footer .container{
        padding-bottom:600px;
        align-items:center
    }
}
footer .footer__content{
    margin-bottom:31px;
    max-width:771px;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:64px;
    color:#000;
    font-family:Grandstander;
    font-size:16px;
    font-style:normal;
    font-weight:300;
    line-height:140%
}
footer .footer__content img{
    width:217px
}
@media screen and (max-width: 500px){
    footer .footer__content{
        display:flex;
        flex-direction:column;
        text-align:center;
        align-items:center;
        justify-content:center
    }
}
footer .footer__links{
    margin-bottom:82px;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:16px
}
@media screen and (max-width: 500px){
    footer .footer__links{
        justify-content:center;
        width:100%
    }
}
footer .footer__links .links__link:hover{
    transition:.3s all;
    opacity:.7;
    transition:.3s all
}
footer .footer__links .links__link img{
    width:64px
}
footer img.picture{
    width:400px;
    position:absolute;
    bottom:0;
    right:-66px;
    pointer-events:none;
    border-radius: 14px;
}
@media screen and (max-width: 500px){
    footer img.picture{
        right:unset
    }
}
footer .footer__other{
    width:100%;
    padding:32px 0;
    border-top:1px solid #3d4148;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    grid-gap:64px;
    color:#000;
    font-family:Grandstander;
    font-size:20px;
    font-style:normal;
    font-weight:400;
    line-height:140%
}
footer .footer__other a{
    color:#000;
    opacity:1;
    transition:.3s all
}
footer .footer__other a:hover{
    opacity:.7;
    transition:.3s all
}
footer .footer__other div{
    opacity:.45
}
@media screen and (max-width: 500px){
    footer .footer__other{
        justify-content:center;
        flex-wrap:wrap;
        grid-gap:18px
    }

}
section.calc{
    margin-bottom:368px
}
@media screen and (max-width: 500px){
    section.calc{
        margin-bottom:168px
    }
}
section.calc .container{
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center
}
section.calc .container::after{
    z-index:-1;
    content:"";
    top:-300px;
    border-radius:1312.954px;
    opacity:.26;
    background:var(--Linear, linear-gradient(99deg, #2ee6a6 1.93%, #3e54c8 47.51%, #7b3ec8 96.04%));
    position:absolute;
    filter:blur(108.0999984741px);
    width:283.716px;
    height:1312.954px;
    transform:rotate(89.541deg)
}
section.calc .calc__title{
    width:100%;
    display:flex;
    align-items:center;
    grid-gap:32px;
    justify-content:flex-start;
    color:#000;
    font-family:Grandstander;
    font-size:72px;
    font-style:normal;
    font-weight:700;
    line-height:normal;
    margin-bottom:26px
}
section.calc .calc__title img{
    width:160px
}
@media screen and (max-width: 500px){
    section.calc .calc__title{
        font-size:46px;
        display:flex;
        flex-direction:column-reverse;
        align-items:flex-start
    }
}
section.calc .calc__block{
    padding:32px;
    width:100%;
    border-radius:24px;
    border:4px solid var(--f-8-f-7-ff, #000);
    display:flex;
    align-items:center;
    justify-content:space-between
}
@media screen and (max-width: 500px){
    section.calc .calc__block{
        flex-wrap:wrap;
        grid-gap:10px
    }
}
section.calc .calc__block .block__title{
    color:#000;
    font-family:Grandstander;
    font-size:36px;
    font-style:normal;
    font-weight:700;
    line-height:normal
}
@media screen and (max-width: 500px){
    section.calc .calc__block .block__title{
        font-size:24px
    }
}
section.calc .calc__block .block__value{
    color:#000;
    text-align:right;
    font-family:Grandstander;
    font-size:24px;
    font-style:normal;
    font-weight:300;
    line-height:normal;
    display:flex;
    align-items:center;
    justify-content:flex-end;
    grid-gap:16px
}
@media screen and (max-width: 500px){
    section.calc .calc__block .block__value{
        font-size:20px
    }
}
section.calc .calc__block .block__value svg{
    cursor:pointer;
    width:32px
}
section.calc .calc__list{
    width:100%;
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    grid-gap:32px;
    margin-bottom:32px
}
@media screen and (max-width: 500px){
    section.calc .calc__list{
        grid-template-columns:repeat(1, 1fr)
    }
}
/*# sourceMappingURL=style.min.css.map */
.text-custome {
    -webkit-text-stroke: 2px #9e087d;
    align-items: center;
    color: #fff;
    display: flex;
    font-family: Comic Story;
    font-size: 120px;
    font-weight: bold;
    justify-content: center;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1.7px;
}

.box-row .title {
    color: #000;
    font-family: Grandstander;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 32px;
    margin-top: 20px;
}
.row-s {
    display: flex;
    align-items: center;
}
.row-s a img {
    width: 50px;
}
.row-s .item:nth-child(2) img {
    width: 70px;
}
/* .row-s .item:nth-child(3) img {
    width: 70px;
} */
.row-s .item {
    margin-right: 15px;
}
.row-s .item:last-child {
    margin-right: 0;
}